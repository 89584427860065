import { setGetUser } from '../../shared/assets/slices/authSlice';
import { Dispatch } from "redux";
import axios from 'axios';

interface WalletProps {
  id: number;
  name: string;
  currency: string;
  balance: string;
  user: string;
  created_on: string;
  modified_on: string;
}

interface UserProps {
  id: number;
  phone_number: string;
  email: string;
  last_login: string;
  is_superuser: boolean;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  user_type: string;
  address: string;
  username: string;
  last_login_ip: string;
  last_login_user_agent: string;
  is_locked: boolean;
  is_activated: boolean;
  avatar: string;
  country: string;
  created_at: string;
  profile_picture: string;
  business_name: string;
  business_certificate: string;
  business_type: string;
  rc_number: string;
  rc_certificate: string;
  tax_id: string;
  tax_certificate: string;
  id_type: string;
  upload_id: string;
  bvn: string;
  nin: string;
  bank_name: string;
  account_number: string;
  have_pin: boolean;
  wallet_pin: string;
  compliant: boolean;
  unique_uid: string;
  groups: any;
  user_permissions: any;
}

interface setUserProps {
  user: UserProps;
  wallet: WalletProps[];
  access_token: string;
  refresh_token: string;
}

const HandleSubmit = async (e: any, payload: any, url: string, method: string, setStatus?: any, setMessage?: any, setUser?: any, accessToken?: string, dispatch?: Dispatch, customheaders?: any) => {
  e.preventDefault();
  const body = payload;
  let response: any;
  const headers = customheaders;

  try {
    if (method === "POST") {
      response = await axios.post(url, body, headers);
    } else if (method === "PUT") {
      response = await axios.put(url, body, headers);
    } else if (method === "DELETE") {
      const config = {
        method: 'delete',
        url: url,
        data: {},
        headers,
      };
      response = await axios.delete(url, headers);
    }

    if (response.data.status === 'error') {
      if (setStatus) setStatus(false);
      if (setMessage) setMessage(response.data.response);
      return false;
    } else {
      if (setMessage) setMessage(response.data.response);
      if (setStatus) setStatus(true);
      if (setUser) setUser(response.data.data);
      if (dispatch && response.data.data) {
        const userData: setUserProps = {
          user: response.data.data,
          wallet: [], 
          access_token: '', 
          refresh_token: '', 
        };
        console.log("user data from handlesubmit: ", userData);
        dispatch(setGetUser(userData));
      }
      return true;
    }
  } catch (error: any) {
    console.error(error);
    if (setStatus) setStatus(false);
    return false;
  }
};

export default HandleSubmit;
