import React from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import clientLogo1 from '../../shared/assets/images/client-1.png';
import clientLogo2 from '../../shared/assets/images/client-2.png';
import clientLogo3 from '../../shared/assets/images/client-3.png';
// import testimonialVideo from '../../shared/assets/videos/testimonial.mp4';

const testimonials = [
    {
        quote: "This platform transformed our payment processing – it's fast, secure, and incredibly user-friendly!",
        name: "Bob Udenze",
        position: "CEO, TechCorp",
        logo: clientLogo1,
    },
    {
        quote: "A seamless experience that has improved our sales conversion by 20% within months!",
        name: "Teni Sobowale",
        position: "Founder, StartSmart",
        logo: clientLogo2,
    },
    {
        quote: "With reliable fraud prevention, we have peace of mind processing transactions globally.",
        name: "Wale Davis",
        position: "Operations Manager, GlobalTrade",
        logo: clientLogo3,
    },
];

const Testimonials = () => {
    const { t } = useTranslation('customer_landing');

    return (
        <section id="testimonials" className="testimonials-section py-5 bg-light">
            <Container>
                {/* Title and Intro */}
                <Row className="text-center mb-5">
                    <Col>
                        <h2 className="display-5 fw-bold text-dark">{t('testimonials')}</h2>
                        <p className="text-muted fs-5">{t('testimonials_description')}</p>
                    </Col>
                </Row>

                {/* Carousel for Testimonials */}
                <Carousel indicators={false} controls={false} interval={5000} className="mb-5">
        {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={10}>
                            <Card className="shadow-sm p-4 d-flex flex-row align-items-center">
                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                    <img
                                        src={testimonial.logo}
                                        alt={`${testimonial.name} logo`}
                                        className="testimonial-image"
                                        width="300"
                                        height="300"
                                    />
                                </Col>
                                <Col md={6}>
                                    <Card.Body className="text-start">
                                        <blockquote className="blockquote mb-4 text-muted">
                                            <p className="fs-5">"{testimonial.quote}"</p>
                                        </blockquote>
                                        <Card.Title className="fs-5 fw-bold">{testimonial.name}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">{testimonial.position}</Card.Subtitle>
                                    </Card.Body>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
        ))}
    </Carousel>

                {/* Optional Video Testimonial */}
                {/* <Row className="justify-content-center mt-4">
                    <Col md={10} lg={8} className="text-center">
                        <h3 className="fs-4 fw-semibold mb-3">{t('case_study')}</h3>
                        <video controls src={testimonialVideo} className="w-100 rounded shadow-sm" />
                    </Col>
                </Row> */}
            </Container>
        </section>
    );
};

export default Testimonials;
