// signupSlice.js
import { createSlice, PayloadAction} from '@reduxjs/toolkit';


interface WalletProps {      
    id: number;
    name: string;
    currency: string;
    balance: string;
    user: string;
    created_on: string;
    modified_on: string;
}

interface UserProps {
  id: number;
  phone_number: string;
  email: string;
  last_login: string;
  is_superuser: boolean;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  user_type: string;
  address: string;
  username: string;
  last_login_ip: string;
  last_login_user_agent: string;
  is_locked: boolean;
  is_activated: boolean;
  avatar: string;
  country: string;
  created_at: string;
  profile_picture: string;
  business_name: string;
  business_certificate: string;
  business_type: string;
  rc_number: string;
  rc_certificate: string;
  tax_id: string;
  tax_certificate: string;
  id_type: string;
  upload_id: string;
  bvn: string;
  nin: string;
  bank_name: string;
  account_number: string;
  have_pin: boolean;
  wallet_pin: string;
  compliant: boolean;
  unique_uid: string;
  groups: any;
  user_permissions: any;
}

interface setUserProps {
user: UserProps;
wallet: WalletProps[];
access_token: string;
refresh_token: string;
}

// Define the type for your slice state
export interface authState {
    displayPart1: boolean,
    displayPart2: boolean,
    getCode1: boolean,
    getCode2: boolean,
    resendCode1: boolean,
    resendCode2: boolean,
    packetStatus: boolean,
    message: string,
    firstName: string,
    lastName: string,
    phoneNo: string,
    currency: string,
    password: string,
    confirmPassword: string,
    email: string,
    emailOtp: string,
    phoneOtp: string,
    country: string,
    isAuthenticated: boolean,
    getUser: setUserProps | null,
    getWallet: any,
}

const initialState: authState = {
    displayPart1: true,
    displayPart2: false,
    getCode1: true,
    getCode2: true,
    resendCode1: false,
    resendCode2: false,
    packetStatus: false,
    message: '',
    firstName: '',
    lastName: '',
    phoneNo: '',
    currency: '',
    country: '',
    password: '',
    confirmPassword: '',
    email: '',
    emailOtp: '',
    phoneOtp: '',
    isAuthenticated: false,
    getUser: null,
    getWallet: null,
  };

export const authSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setDisplayPart1: (state, action) => {
            state.displayPart1 = action.payload;
        },
        setDisplayPart2: (state, action) => {
            state.displayPart2 = action.payload;
        },
        setGetCode1: (state, action) => {
            state.getCode1 = action.payload;
        },
        setGetCode2: (state, action) => {
            state.getCode2 = action.payload;
        },
        setResendCode1: (state, action) => {
            state.resendCode1 = action.payload;
        },
        setResendCode2: (state, action) => {
            state.resendCode2 = action.payload;
        },
        setPacketStatus: (state, action) => {
            state.packetStatus = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNo = action.payload;
        },
        setCountry: (state, action) => {
            state.country = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setConfirmPassword: (state, action) => {
            state.confirmPassword = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setEmailOtp: (state, action) => {
            state.emailOtp = action.payload;
        },
        setPhoneOtp: (state, action) => {
            state.phoneOtp = action.payload;
        },
        setCurrency: (state, action) => {
            state.currency = action.payload;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload
        },
        setGetUser: (state, action: PayloadAction<setUserProps>) => {
            state.getUser?.user ? state.getUser.user = action.payload.user : state.getUser = action.payload;
        },
        setGetWallet: (state, action) => {
            return { ...state.getUser, ...action.payload }
        },
        setGetProduct: (state, action) => {
            return { ...state, ...action.payload }
        },
        resetAuthState: () => initialState,
    },
});

export const { setDisplayPart1, setDisplayPart2, setGetCode1, setGetCode2, setResendCode1, setResendCode2, setPacketStatus, setMessage, setFirstName, setLastName, setPhoneNumber, setCountry, setPassword, setEmail, setEmailOtp, setPhoneOtp, setIsAuthenticated, resetAuthState, setGetUser, setConfirmPassword, setGetWallet, setGetProduct, setCurrency } = authSlice.actions;

export default authSlice.reducer;
