// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { footerData } from '../../constants/index';
import ojapay from '../../shared/assets/images/ojapay.png'
import '../assets/css/Footer.css';

const Footer = () => {
    return (
        <div className="footer text-light py-5">
            <Container>
                <Row>
                    <Col md={3} className="mb-4">
                        <img src={ojapay} alt="logo" className="logo"/>
                    </Col>
                    {/* Footer Links */}
                    <Col md={3} className="mb-4">
                        <h5 className="text-uppercase">Quick Links</h5>
                        <ul className="list-unstyled">
                            {footerData.quickLinks.map((link, index) => (
                                <li key={index}>
                                    <a href={link.href} className="text-light">{link.label}</a>
                                </li>
                            ))}
                        </ul>
                    </Col>

                    {/* Contact Information */}
                    <Col md={3} className="mb-4">
                        <h5 className="text-uppercase">Contact Us</h5>
                        <p className="mb-1">
                            Email: <a href={`mailto:${footerData.contactInfo.email}`} className="text-light">{footerData.contactInfo.email}</a>
                        </p>
                        <p>
                            Phone: <a href={`tel:${footerData.contactInfo.phone}`} className="text-light">{footerData.contactInfo.phone}</a>
                        </p>
                        <p>{footerData.contactInfo.address}</p>
                    </Col>

                    {/* Social Media Links */}
                    <Col md={3} className="mb-4 text-center text-md-start">
                        <h5 className="text-uppercase">Follow Us</h5>
                        <div className="social-links">
                            {footerData.socialLinks.map((social, index) => (
                                <a key={index} href={social.href} className="text-light me-3">
                                    <FontAwesomeIcon icon={social.icon} size="lg" />
                                </a>
                            ))}
                        </div>
                    </Col>
                </Row>
                <hr className="bg-light my-4" />
                {/* Footer Bottom Text */}
                <Row>
                    <Col className="text-center">
                        <p className="mb-0">&copy; {new Date().getFullYear()} Ojapay. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
