import React from 'react';
import './styles/buildMerchants.css';

interface CustomerProps {
  id: number;
  phone_number: string;
  email: string;
  first_name: string;
  last_name: string;
  user_type: string;
  address: string;
  username: string;
  last_login_ip: string;
  last_login_user_agent: string;
  is_locked: boolean;
  is_activated: boolean;
  avatar: string;
  country: string;
  created_at: string,
  profile_picture: string,
  business_name: string,
  business_certificate: string,
  business_type: string,
  rc_number: string,
  rc_certificate: string,
  tax_id: string,
  tax_certificate: string,
  id_type: string,
  upload_id: string,
  bvn: string,
  enter_bvn_number: string,
  bank_name: string,
  account_number: string,
  have_pin: boolean,
  compliant: boolean,
}

const BuildCustomer = (merchant: CustomerProps) => {
  return (
        <div className={merchant.id % 2 === 0 ? 'merch_sec gray' : 'merch_sec '}>
          <p className='merch_txt'>{merchant.first_name}</p>
          <p className='merch_txt'>{merchant.last_name}</p>
          <p className='merch_txt'>{merchant.country}</p>
          <p className='merch_txt'>
            <p className='merch_action '>Details</p>
          </p>
        </div>)
}

export default BuildCustomer