import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

interface PDFInvoiceData {
    date: string;
    transactionType: string;
    amount: number;
    fee: number;
    order: string;
    status: string;
}

interface PDFInvoiceOptions {
    title: string;
    invoiceData: PDFInvoiceData;
    fileName?: string;
}

/**
 * Generates a structured invoice PDF.
 * 
 * @param options - Options for the PDF including title, invoiceData, and fileName.
 */
export const generatePDF = ({
    title,
    invoiceData,
    fileName = 'Transaction_Invoice.pdf'
}: PDFInvoiceOptions): void => {
    const { date, transactionType, amount, fee, order, status } = invoiceData;

    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text(title, 14, 22);
    doc.setFontSize(12);
    doc.text(`Date: ${new Date(date).toLocaleDateString('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })}`, 14, 32);

    // Add transaction details table
    const tableY = 40;
    autoTable(doc, {
        startY: tableY,
        head: [['Description', 'Details']],
        body: [
            ['Transaction Type', transactionType],
            ['Amount', amount.toLocaleString()],
            ['Fee', fee.toLocaleString()],
            ['Order', order],
            ['Status', status],
        ],
        theme: 'grid',
        headStyles: { fillColor: [22, 160, 133] },
        margin: { left: 14, right: 14 },
    });

    // Calculate the Y-coordinate for the total amount text
    const totalAmount = amount + fee;
    const yPos = tableY + 60;
    doc.text(`Total Amount: ${totalAmount.toLocaleString()}`, 14, yPos);

    doc.save(fileName);
};
