import { Carousel, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faCalendar } from '@fortawesome/free-solid-svg-icons';
import slider1 from '../../shared/assets/images/pexels9.png';
import slider2 from '../../shared/assets/images/pexels17.png';
import slider3 from '../../shared/assets/images/guy-with-earpiece.png';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../assets/css/Landing.css';

const HeroSection = () => {
    const { t } = useTranslation('customer_landing');

    return (
        <Carousel fade >
      <Carousel.Item>
        <div className="carousel-overlay"></div> 
        <img
          className="d-block w-100"
          src={slider1}
          alt="First slide"
        />
        <Carousel.Caption className="carousel-caption-custom">
          <h1 className="carousel-heading">{t('seamless_payment')}</h1>
          <h5 className="carousel-text d-none d-md-block">{t('secure_reliable')}</h5>
          <NavLink to="/customer/signup" className="btn default-theme-color default-border-color default-white-text-color btn-lg rounded-pill me-5 ms-5 fw-bold fs-5"><FontAwesomeIcon icon={faRocket} /> {t('get_started')}</NavLink>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-overlay"></div>
        <img
          className="d-block w-100"
          src={slider2}
          alt="Second slide"
        />
        <Carousel.Caption className="carousel-caption-custom">
          <h1 className="carousel-heading">{t('discover_a_world')}</h1>
          <h5 className="carousel-text d-none d-md-block">{t('make_payments')}</h5>
          <NavLink to="/customer/signup" className="btn default-theme-color default-border-color default-white-text-color btn-lg rounded-pill me-5 ms-5 fw-bold fs-5"><FontAwesomeIcon icon={faRocket} /> {t('get_started')}</NavLink>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-overlay"></div>
        <img
          className="d-block w-100"
          src={slider3}
          alt="Third slide"
        />
        <Carousel.Caption className="carousel-caption-custom">
          <h1 className="carousel-heading">{t('designed_for')}</h1>
          <h5 className="carousel-text d-none d-md-block">{t('empowering_innovation')}</h5>
          <NavLink to="/customer/signup" className="btn default-theme-color default-border-color default-white-text-color btn-lg rounded-pill me-5 ms-5 fw-bold fs-5"><FontAwesomeIcon icon={faRocket} /> {t('get_started')}</NavLink>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    );
};

export default HeroSection;
