// import { NavLink } from 'react-router-dom';
// import ojapay from '../../shared/assets/images/ojapay.png';
// import LanguageSwitcher from '../../LanguageSwitcher';
// import { Carousel, Navbar, Nav, Container, Row, Col, Button, Card } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
// import '../assets/css/Landing.css';

// const LandingNavbar = () => {
//   const { t } = useTranslation('customer_landing');

//   return (
//     <Navbar bg="light" expand="lg" className="py-3 navbar-bg">
//       <Container>
//         <Navbar.Brand href="/customer"><img src={ojapay} alt="amapgs" className='rounded-pill' /></Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="mx-auto">
//             <Nav.Link href="#about">{t('about')}</Nav.Link>
//             <Nav.Link href="#services">{t('services')}</Nav.Link>
//             <Nav.Link href="#contacts">{t('contact')}</Nav.Link>
//           </Nav>
//           <Nav className="ml-auto">
//             <Nav.Link><LanguageSwitcher /></Nav.Link>
//             <NavLink to="/customer/login" className="btn btn-custom">{t('login')}</NavLink>
//             <NavLink to="/customer/signup" className="btn btn-custom">{t('signup')}</NavLink>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//   </Navbar>
//   );
// };

// export default LandingNavbar;

import { NavLink } from 'react-router-dom';
import ojapay from '../../shared/assets/images/ojapay.png';
import LanguageSwitcher from '../../LanguageSwitcher';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../assets/css/Landing.css';

const LandingNavbar = () => {
  const { t } = useTranslation('customer_landing');

  return (
    <Navbar bg="light" expand="lg" className="py-3 navbar-bg">
      <Container>
        <Navbar.Brand href="/customer">
          <img src={ojapay} alt="amapgs" className="rounded-pill" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#about">{t('about')}</Nav.Link>
            <Nav.Link href="#services">{t('services')}</Nav.Link>
            <Nav.Link href="#contacts">{t('contact')}</Nav.Link>
          </Nav>
          <Nav className="align-items-center">
            <LanguageSwitcher />
            <NavLink to="/customer/login" className="btn btn-custom mx-2">
              {t('login')}
            </NavLink>
            <NavLink to="/customer/signup" className="btn btn-custom mx-2">
              {t('signup')}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default LandingNavbar;

