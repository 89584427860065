import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../assets/css/Landing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import signInImage from '../../shared/assets/images/login-infographics.svg';
import selectMerchantImage from '../../shared/assets/images/select-merchant-infographics.svg';
import makePaymentImage from '../../shared/assets/images/make-payment-infographics.svg';

const HowItWorks = () => {
    const { t } = useTranslation('customer_landing');

    const steps = [
        { 
            image: signInImage, 
            title: t('sign_in'), 
            description: t('sign_in_description') 
        },
        { 
            image: selectMerchantImage, 
            title: t('select_merchant'), 
            description: t('select_merchant_description') 
        },
        { 
            image: makePaymentImage, 
            title: t('make_payment'), 
            description: t('make_payment_description') 
        },
    ];

    return (
        <section id="how-it-works" className="how-it-works-section py-5 bg-light">
            <Container>
                <Row className="text-center mb-5">
                    <Col>
                        <h2 className="display-5 fw-bold text-dark">{t('how_it_works')}</h2>
                        <p className="text-muted fs-5">{t('how_it_works_description')}</p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {steps.map((step, index) => (
                        <Col md={4} key={index} className="mb-4">
                            <Card className="border-0 shadow-none h-auto text-center p-5 m-0">
                                <img src={step.image} alt={step.title} className="mb-1 infographic-image" />
                                <Card.Body>
                                    <Card.Title className="fs-4 fw-semibold text-dark">{step.title}</Card.Title>
                                    <Card.Text className="text-muted">{step.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                {/* CTA Callout Section */}
                <Row className="justify-content-center mt-5">
                    <Col md={8} className="text-center">
                        <p className="text-muted fs-5 mb-4">{t('cta_text')}</p>
                        <Button variant="primary" href="/customer/contact-us" size="lg" className="rounded-pill default-theme-color default-border-color default-white-text-color px-4 py-2 fw-bold fs-6"><FontAwesomeIcon icon={faHeadset} className="me-2" />{t('contact_support')}</Button>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default HowItWorks;
