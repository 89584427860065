import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faClock, faShieldAlt, faChartBar, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import '../assets/css/Landing.css';

const FeaturesAndBenefits = () => {
    const { t } = useTranslation('customer_landing');

    const features = [
        { icon: faMoneyBillWave, title: t('multi_channel_payments'), description: t('feature_multi_channel') },
        { icon: faClock, title: t('real_time_tracking'), description: t('feature_real_time') },
        { icon: faShieldAlt, title: t('fraud_prevention'), description: t('feature_fraud_prevention') },
        { icon: faExpandArrowsAlt, title: t('scalable_solutions'), description: t('feature_scalable') },
        { icon: faChartBar, title: t('detailed_reporting'), description: t('feature_reporting') },
    ];

    return (
        <section id="features" className="features-section py-5 bg-white">
            <Container>
                <Row className="text-center mb-5">
                    <Col>
                        <h2 className="display-5 fw-bold text-dark">{t('features_benefits')}</h2>
                        <p className="text-muted fs-5">{t('features_description')}</p>
                    </Col>
                </Row>
                <Row>
                    {features.map((feature, index) => (
                        <Col md={6} lg={4} key={index} className="mb-4">
                            <Card className="border-0 shadow-sm h-100 text-center p-3">
                                <FontAwesomeIcon icon={feature.icon} className="default-text-color fs-1 mb-3" />
                                <Card.Body>
                                    <Card.Title className="fs-4 fw-semibold text-dark">{feature.title}</Card.Title>
                                    <Card.Text className="text-muted">{feature.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                {/* CTA Callout Section */}
                <Row className="justify-content-center mt-5">
                    <Col md={8} className="text-center">
                        <h3 className="display-6 fw-bold text-dark">{t('ease_of_use_title')}</h3>
                        <p className="text-muted fs-5">{t('ease_of_use_description')}</p>
                        <button className="btn default-theme-color default-border-color default-white-text-color btn-lg rounded-pill px-5 fs-5 fw-bold">{t('learn_more')}</button>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default FeaturesAndBenefits;
