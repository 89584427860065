import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Container, Row, Col, Modal } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import './styles/TransactionDetails.css';
import Window from '../Window';
import { generatePDF } from '../utils/generatePDF';
import CurrencyDisplay from '../../customer/components/CurrencyDisplay';

interface TransactionDetailsProps {
  id: number;
  amount: number;
  fee: number;
  balance_before: number;
  balance_after: number;
  order: string;
  reference: string;
  note: string;
  gateway: string;
  transaction_type: string;
  payment_type: string;
  status: string;
  date: string;
  user?: {
    id: number;
    first_name: string;
    last_name: string;
    country: string;
    phone_number: string;
    user_type: string;
    address: string;
    email: string;
    is_activated: boolean;
    avatar: string;
    profile_picture: string;
    business_name: string;
    compliant: boolean;
  };
}

const TransactionDetailsView: React.FC<TransactionDetailsProps> = (transaction) => {
  const {
    amount,
    fee,
    balance_after,
    order,
    reference,
    status,
    date,
    user,
  } = transaction;

  const [showModal, setShowModal] = useState(false);

  // Toggle modal visibility
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Handle download button click
  const handleDownloadInvoice = () => {
    generatePDF({
        title: 'Transaction Invoice',
        invoiceData: {
            date: date,
            transactionType: transaction.transaction_type,
            amount: amount,
            fee: fee,
            order: order,
            status: status,
        },
        fileName: 'Transaction_Invoice.pdf'
    });
};


  return (
    <Container className="transaction-details">
      <Card className="mt-4">
        <Card.Header>
          <Button variant="link" href="/admin/transactions" className="text-decoration-none default-text-color border-0">
            <span aria-hidden="true">← </span>Back to Transactions
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h5>{user?.first_name} {user?.last_name}</h5>
              <p>Payment Method: {transaction.payment_type} (Card ending 9054)</p>
              <p>Card Holder: {user ? `${user.first_name} ${user.last_name}` : 'N/A'}</p>
            </Col>
            <Col>
              <p>Amount: <strong><CurrencyDisplay currency='NGN' amount={amount.toLocaleString()} /></strong></p>
              <p>Taxes: <strong><CurrencyDisplay currency='NGN' amount={fee.toLocaleString()} /></strong></p>
              <p>Wallet Balance: <strong><CurrencyDisplay currency='NGN' amount={balance_after.toLocaleString()} /></strong></p>
              <p>Status: <span className={status === 'Declined' ? 'text-danger' : 'text-success'}>{status}</span></p>
            </Col>
            <Col className="text-right">
              <Button 
                className="default-theme-color default-border-color default-text-white-color" 
                onClick={handleShowModal}
              >
                View Invoice
              </Button>
            </Col>
          </Row>
          
          <hr />

          <h6>More Details</h6>
          <Row>
            <Col>
              <p>Item: {order}</p>
              <p>Quantity: 1</p>
              <p>Date: {new Date(date).toLocaleString()}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Invoice Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Invoice</Modal.Title>
          <Button variant="link" onClick={handleDownloadInvoice} className="ml-auto text-decoration-none default-text-color border-0">
            <FaDownload /> Download
          </Button>
        </Modal.Header>
        <Modal.Body>
          <h5>{user?.first_name} {user?.last_name}</h5>
          <p>Date: {new Date(date).toLocaleString()}</p>
          <p>Transaction Type: {transaction.transaction_type}</p>
          <p>Amount: <strong><CurrencyDisplay currency='NGN' amount={amount.toLocaleString()} /></strong></p>
          <p>Taxes: <strong><CurrencyDisplay currency='NGN' amount={fee.toLocaleString()} /></strong></p>
          <p>Wallet Balance: <strong><CurrencyDisplay currency='NGN' amount={balance_after.toLocaleString()} /></strong></p>
          <p>Order: {order}</p>
          <p>Status: <span className={status === 'Declined' ? 'text-danger' : 'text-success'}>{status}</span></p>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

const TransactionDetails: React.FC = () => {
  const location = useLocation();
  const transaction = location.state as TransactionDetailsProps;

  const content = {
    currentView: <TransactionDetailsView {...transaction} />,
    activeId: 2
  };

  return (
    <Window currentView={content.currentView} activeId={content.activeId} />
  );
}

export default TransactionDetails;
