// import React from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import growthImage from '../../shared/assets/images/growth-graphic.jpg';
// import { useTranslation } from 'react-i18next';
// import '../assets/css/Landing.css';

// const AboutUs = () => {
//     const { t } = useTranslation('customer_landing');

//     return (
//         <section id="about" className="about-us-section py-5 bg-light">
//             <Container>
//                 <Row className="align-items-center">
//                     {/* Text Section */}
//                     <Col lg={6} className="mb-4 mb-lg-0">
//                         <h2 className="display-5 fw-bold">{t('about_us')}</h2>
//                         <p className="lead mt-3">
//                             {t('about_description')}
//                         </p>
//                         <ul className="feature-list">
//                             <li>{t('feature_1')}</li>
//                             <li>{t('feature_2')}</li>
//                             <li>{t('feature_3')}</li>
//                         </ul>
//                     </Col>

//                     {/* Image / Graphic Section */}
//                     <Col lg={6}>
//                         <Card className="border-0">
//                             <Card.Img variant="top" src={growthImage} alt="Business Growth" className="rounded-3 shadow-sm"/>
//                         </Card>
//                     </Col>
//                 </Row>
//             </Container>
//         </section>
//     );
// };

// export default AboutUs;

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faChartLine, faHandshake } from '@fortawesome/free-solid-svg-icons';
import growthImage from '../../shared/assets/images/growth-graphic.jpg';
import { useTranslation } from 'react-i18next';
import '../assets/css/Landing.css';

const AboutUs = () => {
    const { t } = useTranslation('customer_landing');

    return (
        <section id="about" className="about-us-section py-5 bg-light">
            <Container>
                <Row className="align-items-center">
                    {/* Text Section */}
                    <Col lg={6} className="mb-4 mb-lg-0">
                        <h2 className="display-4 fw-bold text-dark">{t('about_us')}</h2>
                        <p className="text-muted fs-5 mt-3">
                            {t('about_description')}
                        </p>
                        <div className="feature-list mt-4">
                            <div className="d-flex align-items-start mb-3">
                                <FontAwesomeIcon icon={faShieldAlt} className="text-primary fs-5 me-3"/>
                                <p className="m-0 fs-5 fw-semibold text-dark">{t('feature_1')}</p>
                            </div>
                            <div className="d-flex align-items-start mb-3">
                                <FontAwesomeIcon icon={faChartLine} className="text-success fs-5 me-3"/>
                                <p className="m-0 fs-5 fw-semibold text-dark">{t('feature_2')}</p>
                            </div>
                            <div className="d-flex align-items-start mb-3">
                                <FontAwesomeIcon icon={faHandshake} className="text-info fs-5 me-3"/>
                                <p className="m-0 fs-5 fw-semibold text-dark">{t('feature_3')}</p>
                            </div>
                        </div>
                    </Col>

                    {/* Image / Graphic Section */}
                    <Col lg={6}>
                        <Card className="border-0 shadow-sm rounded-3 overflow-hidden">
                            <Card.Img variant="top" src={growthImage} alt="Business Growth" className="img-fluid"/>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default AboutUs;

