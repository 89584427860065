import React from 'react';

const NumberFormatterDisplay: React.FC<{ amount: string | number}> = ({amount}) => {
    const formattedAmount = formatCurrency(amount);

    return (
        <div>{formattedAmount}</div>
    );
};

const formatCurrency = (amount: string | number): string => {
    const numericAmount = typeof amount === 'string' ? Number(amount) : amount;
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(numericAmount);
};

export default NumberFormatterDisplay;
