import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import papssLogo from '../../shared/assets/images/papss.png';
import ubaLogo from '../../shared/assets/images/uba.png';
import redTechLogo from '../../shared/assets/images/redtech.png';
import coralpayLogo from '../../shared/assets/images/coralpay.jpg';
import dojahLogo from '../../shared/assets/images/dojah.svg';
import sarepayLogo from '../../shared/assets/images/sareppay.png';
import '../assets/css/PartnersIntegrations.css';

const PartnersIntegrations = () => {
    // Array of partners and their information
    const partners = [
        {
            name: 'Uba',
            logo: ubaLogo,
        },
        {
            name: 'Papss',
            logo: papssLogo,
        },
        {
            name: 'RedTech',
            logo: redTechLogo,
        },
        {
            name: 'Coralpay',
            logo: coralpayLogo,
        },
        {
            name: 'Dojah',
            logo: dojahLogo,
        },
        {
            name: 'Sarepay',
            logo: sarepayLogo,
        },
    ];

    return (
        <section id="partners-integrations" className="py-5 bg-light">
            <Container>
                {/* Header Section */}
                <Row className="text-center mb-5">
                    <Col>
                        <h2 className="display-5 fw-bold">Partners & Integrations</h2>
                        <p className="text-muted fs-5">Seamlessly integrate with leading platforms and enhance interoperability.</p>
                    </Col>
                </Row>
                {/* Partner Grid */}
                <Row className="g-4">
                    {partners.map((partner, index) => (
                        <Col xs={12} sm={6} md={2} key={index}>
                            <Card className="border-0 shadow-sm h-100 text-center p-3">
                                <div className="partner-logo mb-3">
                                    <img src={partner.logo} alt={`${partner.name} logo`} className="img-fluid" style={{ height: '80px' }} />
                                </div>
                                <Card.Body>
                                    <Card.Title className="fs-5 fw-semibold">{partner.name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default PartnersIntegrations;
