import React from 'react';
import { Container, Button } from 'react-bootstrap';
import '../assets/css/CallToAction.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';

const CTABanner = () => {
    return (
        <section className="cta-banner d-flex align-items-center text-white py-5">
            <Container className="text-center">
                <h2 className="display-5 fw-bold">Make Payments Easily and Securely</h2>
                <p className="lead mt-3 mb-4">
                    Join thousands of customers who trust our platform for secure and effortless transactions. Start making payments with ease!
                </p>
                <Button variant="primary" size="lg" href="/customer/signup" className="rounded-pill px-4">
                    <FontAwesomeIcon icon={faRocket} className="me-2"/> Get Started
                </Button>
            </Container>
        </section>
    );
};

export default CTABanner;
