import React, { useState } from 'react'
import ojapay from '../shared/assets/images/ojapay.png';
import "./styles/window.css"
import {
    FaRegIdBadge,
    FaBell,
    FaBorderAll,
    FaChartColumn,
    FaGear
} from "react-icons/fa6";
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../shared/assets/stores/store';
import { AppDispatch } from '../shared/assets/stores/store';
import { adminLogout, resetAuthState } from '../shared/assets/slices/adminSlice';
import { faPlusCircle, faUser, faCog, faSignOut } from '@fortawesome/free-solid-svg-icons';
import iziToast from 'izitoast';


type Props = {
    currentView: any
    activeId: number
}

const Window = (props: Props) => {
    const [activeId, setActiveId] = useState<number>(1)
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState(false);
    const admindata = useSelector((state: RootState) => state.admin.getAdmin);
    const {access_token} = admindata.data;
    const { first_name, last_name, username, avatar } = admindata.data.user;

    const handleLogOut = async () => {
        setIsLoading(true);
        try {
            if (access_token) {
                const response = await dispatch(adminLogout(access_token));
                if (response.payload.status === 'success') {
                    dispatch(resetAuthState());
                    navigate('/admin');
                    iziToast.success({
                        title: 'OK',
                        message: 'User logged out successfully!',
                    });
                }
            } 
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: "Something went wrong"
            })
        } finally {
            setIsLoading(false);
        }

    };


    return (
        <div className='wn_super_cont'>
            <header className='wn_admin_header'>
                {/* <div className='wn_profile_pill'> */}
                <img src={ojapay} className="rounded-circle logo" alt="logo" />
                {/* </div> */}
                <div className="navbar-nav">
                    <div className="nav-item dropdown">
                        <NavLink className="d-inline-flex nav-link dropdown-toggle mb-4" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {last_name}
                            <FontAwesomeIcon icon={faPlusCircle} className="add-icon ms-2" />
                        </NavLink>
                        <p className="d-block d-md-none"><span className="badge text-bg-secondary fs-6 p-2">Tag: {username}</span></p>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><NavLink className="dropdown-item mb-3 ts-13" to="/profile"><FontAwesomeIcon icon={faUser} className="add-icon-sm mx-2" /> Profile</NavLink></li>
                            <li><NavLink className="dropdown-item mb-3 ts-13" to="/settings"><FontAwesomeIcon icon={faCog} className="add-icon-sm mx-2" /> Settings</NavLink></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><NavLink to="#" className="dropdown-item mb-3 ts-13"><FontAwesomeIcon icon={faSignOut} className="add-icon-sm mx-2" onClick={handleLogOut} />Logout</NavLink></li>
                        </ul>
                    </div>
                </div>
            </header>
            <main>
                <div className='main_content_container'>
                    <div className='wn_menu_bar'>
                        <ul>
                            <li
                                className={activeId === 1 ? "active" : "inactive"}
                                onClick={() => {
                                    navigate("/admin/dashboard")
                                    setActiveId(1)
                                }}
                            >
                                <FaBorderAll size={15} />
                                Dashboard
                            </li>
                            <li
                                className={activeId === 2 ? "active" : "inactive"}
                                onClick={() => {
                                    navigate("/admin/transactions");
                                    setActiveId(2)
                                }}
                            >
                                <FaChartColumn size={15} />
                                Transactions
                            </li>
                            <li
                                className={activeId === 3 ? "active" : "inactive"}
                                onClick={() => {
                                    navigate("/admin/merchants");
                                    setActiveId(3)
                                }}
                            >
                                <FaChartColumn size={15} />
                                Merchants
                            </li>
                            <li
                                className={activeId === 4 ? "active" : "inactive"}
                                onClick={() => {
                                    navigate("/merchant/security/access-control")
                                    setActiveId(4)
                                }}
                            >
                                <FaGear size={15} />
                                Access Control
                            </li>
                            <li
                                className={activeId === 5 ? "active" : "inactive"}
                                onClick={() => {
                                    navigate("/admin/customers")
                                    setActiveId(5)
                                }}
                            >
                                <FaRegIdBadge size={15} />
                                Customers
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='wn_admin_view_screen'>
                    {
                        props.currentView
                    }
                </div>
            </main>
            <footer className='wn_admin_footer'>
            </footer>
        </div>
    )
}

export default Window
